<template>
    <div class="v-16-input-control">
        <input :type="inputType" class=""  v-bind="$attrs"  v-model="text"
    @input="inputValue">
        <span @click="visible=!visible" class="px-2" style="cursor: pointer;" :title="visible?'Hide':'Show'" v-show="isPassword"> 
            <svg  v-if="visible" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_297_514)">
<path d="M8.24999 3.53337C8.8236 3.39911 9.41088 3.33199 9.99999 3.33337C15.8333 3.33337 19.1667 10 19.1667 10C18.6608 10.9464 18.0575 11.8373 17.3667 12.6584M11.7667 11.7667C11.5378 12.0123 11.2618 12.2093 10.9551 12.346C10.6485 12.4826 10.3174 12.5561 9.98174 12.562C9.64606 12.5679 9.31263 12.5062 9.00134 12.3804C8.69004 12.2547 8.40726 12.0676 8.16987 11.8302C7.93247 11.5928 7.74532 11.31 7.61959 10.9987C7.49385 10.6874 7.4321 10.354 7.43802 10.0183C7.44394 9.68262 7.51742 9.35158 7.65406 9.04491C7.7907 8.73825 7.98771 8.46225 8.23333 8.23337M0.833328 0.833374L19.1667 19.1667M14.95 14.95C13.5255 16.0359 11.7909 16.6374 9.99999 16.6667C4.16666 16.6667 0.833328 10 0.833328 10C1.8699 8.06829 3.30761 6.38055 5.04999 5.05004L14.95 14.95Z" stroke="#475467" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_297_514">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>
<svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.833328 10C0.833328 10 4.16666 3.33337 9.99999 3.33337C15.8333 3.33337 19.1667 10 19.1667 10C19.1667 10 15.8333 16.6667 9.99999 16.6667C4.16666 16.6667 0.833328 10 0.833328 10Z" stroke="#475467" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.99999 12.5C11.3807 12.5 12.5 11.3808 12.5 10C12.5 8.61933 11.3807 7.50004 9.99999 7.50004C8.61928 7.50004 7.49999 8.61933 7.49999 10C7.49999 11.3808 8.61928 12.5 9.99999 12.5Z" stroke="#475467" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</span> 
    </div>

</template>
<script>
export default{
    data(){
        return{
            visible:false,
            text:this.value
        }
    },
    props: {value:String,type:String},
  emits: ['update:value'],
    watch:{
        type(newVal){
            newVal=='password'?'':this.visible=true;
           
        },
        // inputText()
    },
    methods:{
        changeType()
        {
           
            this.visible=!this.visible
        },
        inputValue(){
            // console.log(e.target.value)
            this.$emit('input', this.text)
        }
    },
    computed:{
        isPassword(){
            return this.type=='password'?true:false;
        },
        getId(){
            
           return this.id?this.id:'quizell-input-'+this.type;
          
        },
        inputType(){
           if(this.type=='text') return 'text'
            return this.visible?'text':'password'
        },
       
    

    }
}
</script>
<style scoped>
.quizell-type:focus-within {
    color: #495057;
  background-color: #fff;
  border-color: #f7f6f9;
  outline: 0;
  box-shadow: 0 0 0 0.1rem #4d1b7e;
}
.quizell-form-control{
border: none;
height:48px;
border-radius: 10px;
}
.quizell-form-control:focus-visible{
    border: none!important;;
    outline: none;
}
.quizell-type{
    /* white */
transition: 0.3s ease all;
background: #FFFFFF;
/* greyLight */

border: 1px solid #D2D8E0;
border-radius: 8px;
}
</style>